<html lang="en">
<head>
  <meta charset="UTF-8">
  <meta name="viewport" content="width=device-width, initial-scale=1.0">
  <link href="https://cdn.jsdelivr.net/npm/bootstrap@5.1.3/dist/css/bootstrap.min.css" rel="stylesheet">
  <title>Games Fraser Played in 2022</title>
</head>
<body>

<div class="links">
  <h2>
    <a routerLink="/">2024</a> |
    <a routerLink="/2023">2023</a> |
    <a routerLink="/2022">2022</a> |
    <a routerLink="/2021">2021</a> |
    <a routerLink="/2020">2020</a>
  </h2>

</div>
<router-outlet></router-outlet>

<script src="https://cdn.jsdelivr.net/npm/bootstrap@5.1.3/dist/js/bootstrap.bundle.min.js"></script>
</body>
</html>

